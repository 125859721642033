import React from 'react'
import 'nes.css/css/nes.min.css';
import image from '../images/koala2.png'
import { Navigate, useNavigate } from 'react-router-dom';

function Page2() {
    const nav = useNavigate();
    const click = () => {
        nav("/page3")
    }
 
  return (
    <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    }}>
        <img style={{
            width: '300px',
            height: 'auto',
            marginTop: '5%',
            marginBottom: '1%'
        }} src={image} alt="Description" />
        <p style={{
            marginBottom: '5%',
            textAlign: 'center'
        }}>Look I made a website for you!</p>
        <button className="nes-btn" onClick={click}>you are a nerd</button>
    </div>
  )
}

export default Page2