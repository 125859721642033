import React from "react";
import "nes.css/css/nes.min.css";
import image from "../../images/koalas/k3.png";
import image1 from "../../images/dance1.gif";
import { Navigate, useNavigate } from "react-router-dom";

function Apage3() {
  const nav = useNavigate();

  //   const click = () => {
  //     nav("/apage4");
  //   };

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        style={{
          width: "400px",
          height: "auto",
          marginTop: "5%",
          marginBottom: "1%",
        }}
        src={image1}
        alt="Description"
      />
      <p
        style={{
          marginBottom: "5%",
          textAlign: "center",
        }}
      >
        But fr. You bring me joy everyday!
      </p>
      <p
        style={{
          marginBottom: "3%",
          textAlign: "center",
        }}
      >
        Thanks for always being so considerate and patient with me :D
      </p>
      <p
        style={{
          marginBottom: "3%",
          textAlign: "center",
        }}
      >
        I'll try my best to be the GOAT bf too.
      </p>
      <p
        style={{
          marginBottom: "1%",
          textAlign: "center",
        }}
      >
        Happy 3 months! Looking forward to our future!
      </p>
      <p
        style={{
          marginBottom: "3%",
          textAlign: "center",
        }}
      >
        Sincerely, Me
      </p>
      {/* <button className="nes-btn" onClick={click}>
        thx
      </button> */}
    </div>
  );
}

export default Apage3;
