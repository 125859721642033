import React from "react";
import Gallery from "react-photo-gallery";
import i1 from "../../images/imgs/1.jpg";
import i2 from "../../images/imgs/2.JPG";
import i3 from "../../images/imgs/3.JPG";
import i4 from "../../images/imgs/4.JPG";
import i5 from "../../images/imgs/5.jpg";
import i6 from "../../images/imgs/6.jpg";
import i7 from "../../images/imgs/7.jpg";
import i8 from "../../images/imgs/8.jpg";
import i9 from "../../images/imgs/9.JPG";
import i10 from "../../images/imgs/10.JPG";
import i11 from "../../images/imgs/11.JPG";
import i12 from "../../images/imgs/12.JPG";
import "nes.css/css/nes.min.css";
import { Navigate, useNavigate } from "react-router-dom";

function Apage2() {
  const images = [
    {
      src: i1,
      width: 2.5,
      height: 3,
    },
    {
      src: i2,
      width: 4,
      height: 5,
    },
    {
      src: i3,
      width: 1.5,
      height: 2,
    },
    {
      src: i4,
      width: 1.5,
      height: 2,
    },
    {
      src: i5,
      width: 1.5,
      height: 2,
    },
    {
      src: i6,
      width: 1.5,
      height: 2,
    },
    {
      src: i9,
      width: 1.5,
      height: 2,
    },
    {
      src: i7,
      width: 2,
      height: 3,
    },
    {
      src: i10,
      width: 6,
      height: 4.5,
    },
    {
      src: i8,
      width: 1.5,
      height: 2,
    },

    {
      src: i11,
      width: 2.4,
      height: 3,
    },
    {
      src: i12,
      width: 2,
      height: 3,
    },
  ];

  const nav = useNavigate();
  const click = () => {
    nav("/apage3");
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p
          style={{
            marginTop: "2%",
            textAlign: "center",
          }}
        >
          Here are some of my FAV moments from the past couple months :D
        </p>
        <button
          className="nes-btn"
          style={{ width: "20%", marginBottom: "2%" }}
          onClick={click}
        >
          nice man
        </button>
      </div>

      <Gallery direction="column" photos={images} />
    </div>
  );
}

export default Apage2;
