import React, { useRef } from "react";
import "nes.css/css/nes.min.css";
import Webcam from "react-webcam";
import image1 from "../images/dance1.gif";
import image2 from "../images/dance2.gif";

function Page7() {
  const webcamRef = useRef(null);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    // Do something with the imageSrc, like uploading it to a server
    const downloadLink = document.createElement("a");
    // Set the href attribute to the image data
    downloadLink.href = imageSrc;
    // Set the download attribute to specify the filename
    downloadLink.download = "webcam_image.jpeg";
    // Simulate a click on the link to trigger the download
    downloadLink.click();
  };

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        style={{
          width: "400px",
          height: "auto",
          marginTop: "5%",
          marginBottom: "1%",
        }}
        src={image1}
        alt="Description"
      />
      <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
      <button
        class="nes-btn"
        onClick={capture}
        style={{ marginTop: 10, marginBottom: "10%" }}
      >
        Capture
      </button>
    </div>
  );
}

export default Page7;
