import React from "react";
import "nes.css/css/nes.min.css";
import image from "../images/koala1.png";
import { Navigate, useNavigate } from "react-router-dom";

function Home() {
  const nav = useNavigate();
  const anni = () => {
    nav("/apage1");
  };

  const valen = () => {
    nav("/page1");
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <p
        style={{
          marginBottom: "2%",
          textAlign: "center",
        }}
      >
        BIG DAYS:
      </p>
      <button className="nes-btn" style={{ marginBottom: "2%" }} onClick={anni}>
        3 Months!
      </button>
      <button className="nes-btn" onClick={valen}>
        Valentines
      </button>
    </div>
  );
}

export default Home;
