import React from "react";
import "nes.css/css/nes.min.css";
import image from "../images/koala4.png";
import { Navigate, useNavigate } from "react-router-dom";

function Page5() {
  const nav = useNavigate();
  const click = () => {
    nav("/page6");
  };

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        style={{
          width: "300px",
          height: "auto",
          marginTop: "5%",
          marginBottom: "1%",
        }}
        src={image}
        alt="Description"
      />
      <p
        style={{
          marginBottom: "5%",
          textAlign: "center",
        }}
      >
        Though we kinda establish ed already I thought I should still ask…
      </p>
      <button className="nes-btn" onClick={click}>
        {" "}
        ask what
      </button>
    </div>
  );
}

export default Page5;
