import logo from './logo.svg';
import './App.css';
import 'nes.css/css/nes.min.css';
import { Routes, Route } from "react-router-dom";
import Page1 from './pages/page1'
import Page2 from './pages/page2'
import Page3 from './pages/page3'
import Page4 from './pages/page4'
import Page5 from './pages/page5'
import Page6 from './pages/page6'
import Page7 from './pages/page7'
import Home from './pages/home'
import Apage1 from './pages/anni/apage1';
import Apage2 from './pages/anni/apage2';
import Apage3 from './pages/anni/apage3';
import Apage4 from './pages/anni/apage4';

function App() {
  return (
    <Routes>
      <Route path="/" exact element={<Home />}/>
      <Route path="/apage1" exact element={<Apage1 />} />
      <Route path="/apage2" exact element={<Apage2 />} />
      <Route path="/apage3" exact element={<Apage3 />} />
      <Route path="/apage4" exact element={<Apage4 />} />


      <Route path="/page1" exact element={<Page1 />} />
      <Route path="/page2" exact element={<Page2 />} />
      <Route path="/page3" exact element={<Page3 />} />
      <Route path="/page4" exact element={<Page4 />} />
      <Route path="/page5" exact element={<Page5 />} />
      <Route path="/page6" exact element={<Page6 />} />
      <Route path="/page7" exact element={<Page7 />} />
    </Routes> 
  );
}

export default App;
