import React from "react";
import "nes.css/css/nes.min.css";
import image from "../images/koala5.png";
import { Navigate, useNavigate } from "react-router-dom";

function Page6() {
  const nav = useNavigate();
  const click = () => {
    nav("/page7");
  };

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        style={{
          width: "300px",
          height: "auto",
          marginTop: "5%",
          marginBottom: "1%",
        }}
        src={image}
        alt="Description"
      />
      <p
        style={{
          marginBottom: "5%",
          textAlign: "center",
        }}
      >
        u wanna be my valentine
      </p>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
      <button className="nes-btn" onClick={click}>
        yes
      </button>
    </div>
  );
}

export default Page6;
