import React from "react";
import "nes.css/css/nes.min.css";
import image from "../../images/koalas/k2.png";
import { Navigate, useNavigate } from "react-router-dom";

function Apage1() {
  const nav = useNavigate();

  const click = () => {
    nav("/apage2");
  };

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        style={{
          width: "300px",
          height: "auto",
          marginTop: "5%",
          marginBottom: "1%",
        }}
        src={image}
        alt="Description"
      />
      <p
        style={{
          marginBottom: "5%",
          textAlign: "center",
        }}
      >
        HAPPY 3 MONTHS!!!!
      </p>
      <button className="nes-btn" onClick={click}>
        hooray
      </button>
    </div>
  );
}

export default Apage1;
