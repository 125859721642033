import React from "react";
import "nes.css/css/nes.min.css";
import image from "../../images/koalas/k3.png";
import { Navigate, useNavigate } from "react-router-dom";

function Apage3() {
  const nav = useNavigate();

  const click = () => {
    nav("/apage4");
  };

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        style={{
          width: "300px",
          height: "auto",
          marginTop: "5%",
          marginBottom: "1%",
        }}
        src={image}
        alt="Description"
      />
      <p
        style={{
          marginBottom: "5%",
          textAlign: "center",
        }}
      >
        Thanks for being the best gf eva
      </p>
      <p
        style={{
          marginBottom: "5%",
          textAlign: "center",
        }}
      >
        You da GOAT fr
      </p>
      <button className="nes-btn" onClick={click}>
        thx
      </button>
    </div>
  );
}

export default Apage3;
